
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import isEqual from 'fast-deep-equal/es6';
import { FacebookMessengerPlatformData, SupportedPlatform } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

type FacebookMessengerSettings = Pick<
  FacebookMessengerPlatformData,
  'enabled' | 'pageId' | 'pageAccessToken' | 'pageName' | 'isGetStartedEnabled'
>;

@Component({
  name: 'facebook-messenger-settings-card',
})
export default class FacebookMessengerSettingsCard extends mixins(BotMixin) {
  facebookMessengerSettings = this.resetValue();

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.facebookMessengerSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBotPlatform({
        id: this.bot.id,
        platform: SupportedPlatform.FacebookMessenger,
        data: this.facebookMessengerSettings,
      });
      this.$notify.success('Successfully updated Facebook Messenger-settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating Facebook Messenger-settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.facebookMessengerSettings = this.resetValue();
  }

  private resetValue(): FacebookMessengerSettings {
    return {
      enabled: this.bot.platformData.facebookMessenger.enabled,
      pageId: this.bot.platformData.facebookMessenger.pageId,
      pageAccessToken: this.bot.platformData.facebookMessenger.pageAccessToken,
      pageName: this.bot.platformData.facebookMessenger.pageName,
      isGetStartedEnabled: this.bot.platformData.facebookMessenger.isGetStartedEnabled,
    };
  }
}
